import { DEVICES } from '@constants/global'
import styled from 'styled-components'

export const OtherSuburbs = styled.div`
  margin-bottom: 80px;

  a:hover {
    text-decoration: underline;
  }

  @media ${DEVICES.mobile} {
    margin-bottom: 40px;
  }
`
