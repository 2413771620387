import Autocompete from '@global/PropertySearchFrom/Autocompete'
import PropertyFiltersForm from '@global/PropertySearchFrom/FiltersForm'
import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import Button from '@global/Button'
import IconPlusBig from '@icons/PlusBig'
import { Router } from '@routes'
import {
  LISTING_STATUS,
  LISTING_TYPE,
  PROPERTY_CLASS,
  PROPERTY_RESULTS_PATH,
} from '@constants/property'
import { propertyCategory } from '@lib/searchFields'
import { UrlBuilder, currencyFormat, numberFormat } from '@lib/helpers'
import { Formik } from 'formik'
import * as S from './SearchResultsForm.styled'
import { useSiteSettingsContext } from '@context/SiteSettingsContext'
import { OfficeSiteContext } from '@context/OfficeSiteContext'

const getSelectedCategoryLabel = (value) => {
  const selectedOption = Object.values(propertyCategory)
    .reduce((accumulator, currentValue) => {
      return (accumulator = accumulator.concat(currentValue.options))
    }, [])
    .find((it) => it.value.includes(value))

  return selectedOption?.label
}

const getSelectedFilterFromQueryString = (query) => {
  if (!query) return []

  const selectedFilters = []
  const addFilter = (filter) => selectedFilters.push(filter)

  const addListingTypeFilter = () => {
    if (PROPERTY_RESULTS_PATH.sold.includes(query.status)) {
      addFilter('Sold')
    } else if (PROPERTY_RESULTS_PATH.sale.includes(query.listing_type)) {
      addFilter('For sale')
    } else {
      addFilter('For lease')
    }
  }

  const addPropertyClassFilter = () => {
    if (query.property_class) {
      addFilter(
        query.property_class === PROPERTY_CLASS.RESIDENTIAL
          ? 'Residential'
          : 'Commercial'
      )
    }
  }

  const addGTEFilter = (key, label) => {
    if (query[key]) {
      addFilter(`${label ? `${label}` : ''} +${query[key]}`)
    }
  }

  const addCategoriesFilter = () => {
    if (query.categories) {
      const categories = query.categories.split(',')
      addFilter(
        `${getSelectedCategoryLabel(categories[0])}${
          categories.length - 1 > 0 ? ` +${categories.length - 1}` : ''
        }`
      )
    }
  }

  const addRangeFilter = (
    min,
    max,
    suffix,
    formatFunction = numberFormat,
    secondParam = false
  ) => {
    if (min || max) {
      const formatMin = min
        ? `${formatFunction(min, secondParam).toLowerCase()}${suffix}`
        : 'Any'
      const formatMax = max
        ? `${formatFunction(max, secondParam).toLowerCase()}${suffix}`
        : 'Any'
      addFilter(`${formatMin} - ${formatMax}`)
    }
  }

  addPropertyClassFilter()
  addGTEFilter('bathrooms__gte', 'Baths')
  addGTEFilter('bedrooms__gte', 'Beds')
  addGTEFilter('parking__gte', 'Cars')
  addCategoriesFilter()
  addRangeFilter(query.land_size_min, query.land_size_max, 'sqm')
  addRangeFilter(query.area__gte, query.area__lte, 'sqm')
  addRangeFilter(query.price__gte, query.price__lte, '', currencyFormat, true)
  addRangeFilter(query.rent__gte, query.rent__lte, '', currencyFormat, true)

  return selectedFilters
}

const SearchResultsForm = ({ queryParams, children }) => {
  const { currentSite } = useContext(OfficeSiteContext)
  const { settings } = useSiteSettingsContext()
  const searchsettings = settings?.searchsettings

  const [isShowFilters, setShowFilters] = useState(false)
  const [activeTab, setActiveTab] = useState(LISTING_TYPE.SALE)

  const selectedFilters = getSelectedFilterFromQueryString(queryParams)

  const toggleFilters = () => {
    setShowFilters((prev) => !prev)
  }

  useEffect(() => {
    let selectedTab = queryParams.listing_type
    if (queryParams.status === LISTING_STATUS.SOLD) {
      selectedTab = LISTING_STATUS.SOLD
    }
    setActiveTab(selectedTab)
  }, [queryParams?.listing_type, queryParams?.status])

  const onSubmit = async (values) => {
    const searchPage = currentSite?.searchSettings || searchsettings

    let slug = `/${Router.router.query.slug}/`
    let params = { ...values }
    if (params.listing_type === LISTING_STATUS.SOLD) {
      params.status = LISTING_STATUS.SOLD
    }
    if (params.property_class === PROPERTY_CLASS.RESIDENTIAL) {
      delete params.area__gte
      delete params.area__lte
    } else {
      // commercial
      delete params.bathrooms__gte
      delete params.bathrooms__lte
      delete params.bedrooms__gte
      delete params.bedrooms__lte
      delete params.hide_under_offer
    }

    if (params.surrounding === false) {
      delete params.surrounding
      delete params.bordering
    }

    if (params.status === LISTING_STATUS.SOLD && searchPage.sold_search) {
      slug = searchPage.sold_search
    } else if (params?.property_class == PROPERTY_CLASS.COMMERCIAL) {
      // commercial search
      slug =
        // is there a commercial search page?
        searchPage.commercial_search
          ? searchPage.commercial_search
          : // if no - is listing type sale?
          params?.listing_type === LISTING_TYPE.SALE
          ? searchPage.residential_sale_search || slug
          : // if no - is listing type lease?
          params?.listing_type === LISTING_TYPE.LEASE
          ? searchPage.residential_rent_search || slug
          : slug
    } else if (params?.property_class === PROPERTY_CLASS.PROJECT) {
      // projects search
      slug = searchPage.projects_search || slug
    } else if (
      params?.listing_type === LISTING_TYPE.SALE &&
      params.status !== LISTING_STATUS.SOLD
    ) {
      // buy (sale) search
      slug = searchPage.residential_sale_search || slug
    } else if (
      params?.listing_type === LISTING_TYPE.LEASE &&
      params.status !== LISTING_STATUS.SOLD
    ) {
      // rent (lease) search
      slug = searchPage.residential_rent_search || slug
    }

    setShowFilters(false)

    await Router.pushRoute(UrlBuilder(slug, params))
  }

  const initialValues = useMemo(() => {
    const params = { ...queryParams }
    delete params.page
    delete params.per_page
    delete params.apContact

    if (params.categories) {
      let categories = []

      // Check if 'Apartment,Unit' is in the string and handle it separately
      if (params.categories.includes('Apartment,Unit')) {
        categories = params.categories.replace('Apartment,Unit', '').split(',')
        categories = categories.filter((category) => category.trim() !== '') // Remove any empty strings
        categories.push('Apartment,Unit')
      } else {
        categories = params.categories.split(',')
      }

      params.categories = categories
    } else {
      params.categories = []
    }

    return { ...params }
  }, [queryParams])

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {({ handleSubmit, setValues, values }) => (
        <form noValidate onSubmit={handleSubmit}>
          <S.SearchResultsForm>
            <S.AutocompeteWrap>
              <Autocompete
                listingType={queryParams.listing_type}
                status={queryParams.status}
                onChange={(data) => setValues({ ...values, ...data })}
              />
              <div>
                <Button type='submit' color='secondary'>
                  Search
                </Button>
              </div>
            </S.AutocompeteWrap>
            <S.SelectedFilters>
              {selectedFilters?.map(
                (it, index) =>
                  index <= 2 && (
                    <S.SelectedFilterItem key={`selectedFilter-${index}`}>
                      {it}
                    </S.SelectedFilterItem>
                  )
              )}
              {/* Remaining selected filters */}
              {selectedFilters?.length - 3 > 0 && (
                <S.SelectedFilterItem>
                  +{selectedFilters?.length - 3}
                </S.SelectedFilterItem>
              )}

              {/* Mobile */}
              {selectedFilters?.length > 0 && (
                <S.NumberFilters>+{selectedFilters?.length}</S.NumberFilters>
              )}
              <Button
                color='secondary'
                type='button'
                startIcon={<IconPlusBig />}
                onClick={toggleFilters}
                endIcon={<IconPlusBig />}
              >
                Filter
              </Button>
            </S.SelectedFilters>
          </S.SearchResultsForm>

          <S.SearchResultsFormMobile>
            <S.AutocompeteWrap>
              <Autocompete
                className='search-input'
                listingType={queryParams.listing_type}
                status={queryParams.status}
                onChange={(data) => {
                  setValues({ ...values, ...data })
                }}
                iconSearchClick={handleSubmit}
              />
            </S.AutocompeteWrap>
            <S.SelectedFilters>
              {selectedFilters?.map(
                (it, index) =>
                  index <= 2 && (
                    <S.SelectedFilterItem key={`selectedFilter-${index}`}>
                      {it}
                    </S.SelectedFilterItem>
                  )
              )}
              {/* Remaining selected filters */}
              {selectedFilters?.length - 3 > 0 && (
                <S.SelectedFilterItem>
                  +{selectedFilters?.length - 3}
                </S.SelectedFilterItem>
              )}

              {/* Mobile */}
              {selectedFilters?.length > 0 && (
                <S.NumberFilters>+{selectedFilters?.length}</S.NumberFilters>
              )}
              <Button
                color='secondary'
                type='button'
                startIcon={<IconPlusBig />}
                onClick={toggleFilters}
                endIcon={<IconPlusBig />}
              >
                Filter
              </Button>
            </S.SelectedFilters>
          </S.SearchResultsFormMobile>

          <PropertyFiltersForm
            open={isShowFilters}
            toggleFilters={toggleFilters}
            listingType={queryParams.listing_type}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
          />

          {children}
        </form>
      )}
    </Formik>
  )
}

export default SearchResultsForm
