import { titleCase } from '@lib/helpers'
import { Link } from '@routes'

import * as S from './OtherSuburbs.styled'
import { LISTING_TYPE } from '@constants/property'

const OtherSuburbs = ({ otherSuburbs, listingType }) => {
  const suburbs = otherSuburbs.slice(0, 3)

  const getSuburbLink = (suburb, listingType) => {
    let url = `/for-sale/properties-sale/?address_suburb=${titleCase(suburb)}`

    if (listingType === LISTING_TYPE.LEASE) {
      url = `/rental-properties/properties-rent/?address_suburb=${titleCase(
        suburb
      )}`
    }

    return url
  }

  return (
    <S.OtherSuburbs>
      You may also be interested in properties in{' '}
      {suburbs?.map((it, key) => (
        <>
          {suburbs.length > 1 && key === suburbs.length - 1 ? ' and ' : ''}
          <Link
            route={getSuburbLink(it.suburb, listingType)}
            key={`suburb-${key}`}
          >
            {titleCase(it.suburb)}
            {key >= suburbs.length - 2 ? '' : ', '}
          </Link>
        </>
      ))}
    </S.OtherSuburbs>
  )
}

export default OtherSuburbs
